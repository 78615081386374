@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: 'Kalimati', 'sans-serif';
}

.nonEditable {
	color: red;
}

.page-div::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.page-div::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.page {
	display: block;
	width: 21cm;
	min-height: 27cm;
	height: fit-content;
	padding: 0.5cm;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	position: relative;
	margin: 20px 0;
	background-color: white;
}

body {
	background-color: #f7f8fc;
}

.appmenu {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.tooltip:before {
	font-size: 0.75rem;
}

/* .table :where(tbody th, tbody td) {
  background-color: #eaf1fb;
} */
.table
	:where(.table *:first-child)
	:where(*:first-child)
	:where(th, td):first-child,
:where(.table *:first-child) :where(*:first-child) :where(th, td):last-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
:where(.table *:last-child) :where(*:last-child) :where(th, td):first-child,
:where(.table *:last-child) :where(*:last-child) :where(th, td):last-child {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.table :where(th, td) {
	padding: 0.5rem !important;
	vertical-align: initial;
}

.settings-content-container {
	height: calc(100vh - 15rem);
	overflow-y: auto;
	padding-right: 15px;
}

.main-content-container {
	height: calc(100vh - 13.7rem);
	overflow-y: auto;
}
.main-content-container::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.main-content-container::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.no-top-content-container {
	height: calc(100vh - 10.2rem);
	overflow-y: auto;
}

.secondary-inbox-content-container {
	height: calc(100vh - 15.2rem);
	overflow-y: auto;
}

.no-top-content-container::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.no-top-content-container::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.mail-content-container {
	height: calc(100vh - 10rem);
	overflow-y: auto;
}
.mail-content-container::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.mail-content-container::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.sideImagScroll::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.sideImagScroll::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.low {
	@apply border-l-blue-200;
}
.medium {
	@apply border-l-yellow-400;
}
.high {
	@apply border-l-orange-600;
}
.urgent {
	@apply border-l-red-600;
}

.is-invalid {
	@apply border-2 border-red-600 rounded;
}
.error-text {
	@apply text-sm absolute text-red-500 mt-0.5;
}

.scroll-content {
	overflow-y: auto;
	height: calc(100vh - 10.2rem);
}
.scroll-content::-webkit-scrollbar {
	width: 0.6em;
	border-radius: 10px;
}

.scroll-content::-webkit-scrollbar-thumb {
	background-color: rgb(149, 149, 149);
	border-radius: 10px;
}

.radio-inputDiv {
	display: flex;
}

.low-label {
	display: flex;
	align-items: center;
}
.low-label::before {
	content: '';
	width: 6px;
	height: 15px;
	display: block;
	margin-right: 2px;
	@apply bg-blue-200;
}
.medium-label {
	display: flex;
	align-items: center;
}
.medium-label::before {
	content: '';
	width: 6px;
	height: 15px;
	display: block;
	margin-right: 2px;
	@apply bg-yellow-400;
}
.high-label {
	display: flex;
	align-items: center;
}
.high-label::before {
	content: '';
	width: 6px;
	height: 15px;
	display: block;
	margin-right: 2px;
	@apply bg-orange-600;
}
.urgent-label {
	display: flex;
	align-items: center;
}
.urgent-label::before {
	content: '';
	width: 6px;
	height: 15px;
	display: block;
	margin-right: 2px;
	@apply bg-red-600;
}

.page-footer {
	width: 100%;
	display: flex;
	justify-content: space-around;
	font-weight: 300;
	gap: 0;
}

.header-text {
	margin-top: 8px;
	font-size: 0.8rem;
}

@media print {
	body {
		margin: 0 !important;
		padding: 0 !important;
		box-shadow: 0 !important;
	}

	@page {
		/* size: 5mm 5mm 10mm 5mm !important; */
		/* margin: 0mm 5mm 0mm 5mm !important; */
		margin: 0.3cm 0mm 0mm !important;
		size: 0 5mm 0 5mm !important;
	}

	.header {
		z-index: 1000;
		width: 100%;
		height: 268px;
		padding: 0cm 0.25cm 0 0.25cm !important;
	}

	.header-text {
		margin-top: 8px;
		font-size: 0.8rem;
	}

	.page {
		width: 100% !important;
		height: 100% !important;
		min-height: 29.68cm;
		max-height: 100%;
		padding: 0 0.4cm 0.2cm 0.25cm !important;
		box-sizing: border-box;
		margin: 0px !important;
		border: none !important;
		box-shadow: none;
		background-color: rgb(255, 255, 255) !important;
	}

	.paper-footer-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		min-height: 29.68cm;
	}

	.footer {
		position: relative;

		width: 100% !important;
		min-height: 29.68cm;
	}

	.A4 {
		margin: 0 !important;
		padding: 0 !important;
		width: 100%;
	}

	.sidelogo {
		width: 120px;
	}

	.logo {
		width: 100px;
	}

	.page-break {
		page-break-before: always !important;
	}

	.break-line {
		display: none;
	}

	.footer-space {
		height: 35px;
	}

	.content {
		width: 100% !important;
		height: auto !important;
		margin: 0 !important;
		padding: 0 !important;
		background-color: white !important;
	}

	.hide-in-print {
		display: none;
	}

	.print-img-previewer-container {
		padding: 0 !important;
		height: auto !important;
		background-color: white !important;
	}

	.print-img-container {
		display: flex;
		flex-direction: column;
	}

	.print-mail-img {
		display: block;
		width: 20cm !important;
		margin: auto;
	}

	.mail-content-container {
		width: 100% !important;
		height: 100% !important;
		margin: 0;
	}

	.page-footer {
		display: flex;
		position: absolute !important;
		padding: 0 1rem;
		bottom: 12px;
		left: 0;
		font-size: 0.7rem !important;
		border: none !important;
	}

	.table :where(th, td) {
		border-color: black;
	}

	.footer-space {
		height: 90px;
	}
}

.signatory-line {
	@apply w-full border-b border-slate-600 border-dotted mb-2;
}

.d-flex {
	@apply flex;
}

.justify-content-end {
	@apply justify-end;
}

.ganesh-font {
	font-family: 'ganessregular';
}

.nagarpalika-heading {
	color: red;
}

.sidelogo {
	width: 150px;
	height: 125px;
	object-fit: contain;
	position: relative;
	right: -20px;
}
.logo {
	width: 130px;
	height: 125px;
	object-fit: contain;
}

.right-text-1 {
	margin-top: 0.75rem;
}

.right-text-2 {
	line-height: 1.5rem;
}

.leading-3_5 {
	line-height: 0.9rem;
}
.leading-4 {
	line-height: 1rem;
}
.leading-6 {
	line-height: 1.5rem;
}

.header-text-4xl {
	font-size: 2.24rem;
	line-height: 1.5rem;
}
.nagarpalika-qr {
	height: 60px;
	width: 60px;
	margin-right: 8px;
}

.nagarpalika-footer {
	font-size: 0.84rem;
	color: red;
	padding: 8px 0;
}

.nagarpalika-link {
	font-size: 0.78rem;
}

.tooltip-info::before {
	color: white !important;
}

.wardform-label {
	font-weight: 600;
	color: #34395e;
	font-size: 14px;
	letter-spacing: 0.5px;
}

.wardform-input {
	width: 100%;
	border-radius: 0.25rem;
	height: calc(1.5em + 0.75rem + 2px);
	border: 1px solid rgb(204, 204, 204) !important;
	font: 14px Roboto-Regular !important;
	color: rgb(51, 51, 51) !important;
	padding: 3px 4px !important;
	background-color: rgb(255, 255, 255) !important;
}

.mini-icons svg {
	margin-left: 4px;
}

.input {
	height: 2.4rem;
}

.modal-children {
	max-height: calc(100vh - 8em);
}

.glassmorphism {
  background: rgba( 255, 255, 255, 0.5 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 6.5px );
  -webkit-backdrop-filter: blur( 6.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
